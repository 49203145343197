// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { localize, localizeLink } from 'utilities';
import { useTheme, useResponsive } from 'hooks';
import { TransitionLink } from 'transition';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import PageIntroduction from 'components/pageIntroduction';
import Image from 'components/image';
import Button from 'components/button';
import Ticker from 'components/ticker';

const CoursesPageComponent = ({ pageContext, content, location }) => {
    const { locale, labels, allSwellProducts } = pageContext;
    const { seoMetaTags, title, courses, introduction, slug } = content;

    // Currency
    const [currency] = useGlobal('currency');

    // Set theme
    const theme = useTheme(s.color('white'));

    // Breakpoint
    const breakpoint = useResponsive();

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <PageNavigation {...{ theme }}>
                        <span>{title}</span>
                    </PageNavigation>
                    <PageIntroduction {...{ content: introduction }} />
                    <Courses>
                        {[...courses].map(course => (
                            <Course key={course.id}>
                                <TransitionLink
                                    {...{
                                        from: location,
                                        to: localizeLink(
                                            `/${slug}/${course.slug}/`,
                                            locale
                                        ),
                                        title: course.name,
                                        transition: [
                                            'default',
                                            {
                                                theme: s.themeColor({
                                                    color: 'categories',
                                                    index: 2,
                                                }),
                                            },
                                        ],
                                    }}>
                                    <Details>
                                        <Title>{course.name}</Title>
                                        {breakpoint < 20 && (
                                            <ImageWrapper>
                                                <Image
                                                    {...{
                                                        source: course.image
                                                            .fluid,
                                                        alt: course.name,
                                                    }}
                                                />
                                            </ImageWrapper>
                                        )}
                                        <Price>
                                            {`${
                                                JSON.parse(course.price)[
                                                    currency
                                                ] ?? 'N/A'
                                            } ${labels.CURRENCY[currency]}`}
                                        </Price>
                                        <Description>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: course.teaser,
                                                }}
                                            />
                                            <Button
                                                {...{
                                                    theme: s.color('black'),
                                                    type: 'secondary',
                                                    label: labels.PAGES.COURSES
                                                        .READ_MORE,
                                                }}
                                            />
                                        </Description>
                                    </Details>
                                    {breakpoint > 10 && (
                                        <ImageWrapper>
                                            <Image
                                                {...{
                                                    source: course.image.fluid,
                                                    alt: course.name,
                                                }}
                                            />
                                        </ImageWrapper>
                                    )}
                                    <Ticker
                                        {...{
                                            text: course.ticker,
                                            theme: s.color('black'),
                                            locale,
                                        }}
                                    />
                                </TransitionLink>
                            </Course>
                        ))}
                    </Courses>
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    ${s.responsive.property('padding-top', {
        0: 100,
        30: 140,
    })};
`;

const Courses = styled.div`
    display: flex;
    flex-wrap: wrap;

    margin-top: 2em;
`;

const Course = styled.div`
    ${s.layout.fullWidthBackground(
        s.themeColor({ color: 'categories', index: 2 }).alternating()
    )};

    &:nth-of-type(2n) {
        ${s.layout.fullWidthBackground(
            s.themeColor({ color: 'categories', index: 2 }).default()
        )};
    }

    color: ${s.color('black').text()};

    ${s.grid.columns('width', {
        0: 4,
        20: 6,
        30: 12,
    })};

    ${s.responsive.property(['padding-top', 'padding-bottom'], {
        0: 60,
        30: 80,
    })};
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;

    ${s.responsive.mq(20)} {
        ${s.responsive.property('top', {
            0: 130,
        })};
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
    }
`;

const Title = styled.div`
    ${s.fonts.style('h5')};
    width: 100%;
    border-bottom: 1px solid currentColor;
    padding-bottom: 0.3em;

    ${s.responsive.property('margin-bottom', {
        0: '0.8em',
        30: '0em',
    })};
`;

const Price = styled.div`
    ${s.fonts.style('h9')};

    ${s.responsive.property('margin-top', {
        0: '1em',
        30: '2.5em',
    })};

    ${s.responsive.property('margin-bottom', {
        0: '0.5em',
        20: '1em',
    })};

    span {
        display: block;
        ${s.fonts.style('h17')};
    }
`;

const Description = styled.div`
    ${s.responsive.property('margin-bottom', {
        0: '1.5em',
        20: '2em',
        30: '3em',
        40: '4em',
    })};

    ${s.grid.columns('width', {
        0: 4,
        20: 3,
        30: 5,
    })};

    p {
        ${s.fonts.style('h11')};
    }

    button {
        margin-top: 2em;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    ${s.grid.columns('margin-left', {
        0: [0, 0],
        20: [0.5, 0],
        30: [1, 0],
    })};
    &::after {
        content: '';
        background: ${s.color('black').alpha(0.7)};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;

const NamedCoursesPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageCourse, allDatoCmsCourse } = useStaticQuery(graphql`
        query {
            allDatoCmsPageCourse {
                edges {
                    node {
                        locale
                        title
                        introduction
                        seoMetaTags {
                            ...SEO
                        }
                        slug
                    }
                }
            }
            allDatoCmsCourse(filter: { active: { eq: true } }) {
                edges {
                    node {
                        id
                        locale
                        name
                        slug
                        price
                        teaser
                        sku
                        image {
                            fluid(maxWidth: 1600) {
                                ...GatsbyDatoCmsFluid_noBase64
                            }
                        }
                        ticker
                    }
                }
            }
        }
    `);

    return (
        <CoursesPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageCourse, locale),
                    courses: localize(allDatoCmsCourse, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedCoursesPageComponent;
